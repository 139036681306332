import React from 'react';

interface Props {
	className?: string;
	color?: string;
}

const TopOrnament = ({ className, color = '#FBBB00' }: Props) => (
	<svg
		width="330"
		height="204"
		viewBox="0 0 330 204"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path d="M329.664 203.456L0 0H329.664V203.456Z" fill={color} />
	</svg>
);

export default TopOrnament;
