import React from 'react';

interface Props {
	className?: string;
	color?: string;
}

const BottomOrnament = ({ className = '', color = '#FBBB00' }: Props) => {
	return (
		<svg
			width="447"
			height="164"
			viewBox="0 0 447 164"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M227.368 325.813L227.368 249.776L596 249.776L596 76.0377L227.368 76.0376L227.368 0.000242431L0.000136312 162.907L227.368 325.813Z"
				fill={color}
			/>
		</svg>
	);
};

export default BottomOrnament;
