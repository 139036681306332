interface Props {
	className?: string;
	size?: number;
	color?: string;
}

const InstagramIcon = ({ className = '', color = 'inherit', size = 24 }: Props) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 24 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			d="M17 2.48999H7C4.23858 2.48999 2 4.72857 2 7.48999V17.49C2 20.2514 4.23858 22.49 7 22.49H17C19.7614 22.49 22 20.2514 22 17.49V7.48999C22 4.72857 19.7614 2.48999 17 2.48999Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.9997 11.86C16.1231 12.6923 15.981 13.5423 15.5935 14.2891C15.206 15.0359 14.5929 15.6415 13.8413 16.0197C13.0898 16.398 12.2382 16.5296 11.4075 16.396C10.5768 16.2623 9.80947 15.8701 9.21455 15.2752C8.61962 14.6803 8.22744 13.9129 8.09377 13.0823C7.96011 12.2516 8.09177 11.3999 8.47003 10.6484C8.84829 9.8969 9.45389 9.28379 10.2007 8.89629C10.9475 8.50879 11.7975 8.36664 12.6297 8.49005C13.4786 8.61593 14.2646 9.01151 14.8714 9.61835C15.4782 10.2252 15.8738 11.0111 15.9997 11.86Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M17.5 6.98999H17.51"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default InstagramIcon;
