'use client';

import { Content, isFilled } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText, SliceComponentProps } from '@prismicio/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { LayoutContextType } from '../../@types/prismic.types';
import Logo from '../../components/_shared/Logo';
import { PrismicUnrichText } from '../../components/_shared/PrismicUnrichtext';
import BottomOrnament from './components/BottomOrnament';
import FacebookIcon from './components/FacebookIcon';
import InstagramIcon from './components/InstagramIcon';
import TopOrnament from './components/TopOrnament';
import TwitterIcon from './components/TwitterIcon';

/**
 * Props for `Footer`.
 */
export type FooterProps = SliceComponentProps<Content.FooterSlice, LayoutContextType>;

/**
 * Component for "Footer" Slices.
 */
const Footer = ({ slice }: FooterProps): JSX.Element => {
	const {
		description,
		company_info_header,
		information_header,
		opening_hours_text,
		get_in_touch_header,
		facebook_link,
		twitter_link,
		instagram_link,
		certification_1,
		certification_2,
		copyright_text,
	} = slice.primary;
	const companyInfoMenus = slice.items.filter((item) => item.menu_type === 'company-info');
	const informationMenus = slice.items.filter((item) => item.menu_type === 'information');

	// return null if its product detail page
	const routesWithoutFooter = [
		'/products/',
		'/checkout',
		'/checkout/pickup',
		'/checkout/success',
		'/deals/',
	];

	const pathname = usePathname();

	if (typeof window === 'undefined') {
		return <></>;
	}

	if (routesWithoutFooter.some((route) => pathname.includes(route))) {
		return <></>;
	}

	return (
		<footer
			data-slice-type={slice.slice_type}
			data-slice-variation={slice.variation}
			className="relative w-full bg-theme-green pb-8 lg:pb-0"
			id="footer-layout"
		>
			{/* Ornaments */}
			<TopOrnament className="absolute right-0 top-0 -lg:h-auto -lg:w-[167px]" />
			<BottomOrnament className="absolute bottom-0 right-0 hidden lg:block" />

			<div className="container relative z-[2] w-full pt-16 lg:pt-28">
				<Logo className="mb-8 h-[36px] w-auto lg:mb-16 lg:h-[50.8px]" />

				{/* Main Content */}
				<div className="mb-36 w-full lg:grid lg:grid-cols-12">
					<section className="w-full text-white lg:col-span-4">
						<div className="text-sm lg:text-base">
							<PrismicRichText field={description} />
						</div>

						<div className="mt-8">
							<PrismicNextImage field={certification_1} className="mr-5 inline" />
							<PrismicNextImage field={certification_2} className="inline" />
						</div>
					</section>

					<div className="lg:flex-bs mt-10 grid grid-cols-2 gap-6 text-white lg:col-span-8 lg:ml-16 lg:mt-0 lg:gap-8">
						{/* Company Info Section */}
						<section>
							<PrismicUnrichText
								field={company_info_header}
								className="mb-2 text-sm font-medium uppercase lg:mb-4 lg:text-lg"
							/>

							<ul className="w-full text-sm font-normal lg:text-base">
								{companyInfoMenus.map((item, index) => (
									<li key={index}>
										<Link
											href={
												isFilled.keyText(item.menu_link)
													? item.menu_link
													: '#'
											}
											className="inline-block py-1 hover:text-theme-yellow-text"
										>
											{item.menu_text}
										</Link>
									</li>
								))}
							</ul>
						</section>

						{/* Information Section */}
						<section>
							<PrismicUnrichText
								field={information_header}
								className="mb-2 text-sm font-medium uppercase lg:mb-4 lg:text-lg"
							/>

							<ul className="w-full text-sm font-normal lg:text-base">
								{informationMenus.map((item, index) => (
									<li key={index}>
										<Link
											href={
												isFilled.keyText(item.menu_link)
													? item.menu_link
													: '#'
											}
											className="inline-block py-1 hover:text-theme-yellow-text"
										>
											{item.menu_text}
										</Link>
									</li>
								))}

								{opening_hours_text && (
									<li key="opening-hours" className="py-3">
										<Link
											href="/"
											className="inline-block rounded-full bg-white px-4 py-1.5 text-sm font-medium !text-theme-dark transition-colors hover:bg-gray-100 lg:text-base"
										>
											{opening_hours_text}
										</Link>
									</li>
								)}
							</ul>
						</section>

						{/* Get in touch section */}
						<section>
							<PrismicUnrichText
								field={get_in_touch_header}
								className="mb-4 text-sm font-medium uppercase lg:mb-4 lg:text-lg"
							/>

							<ul className="flex-sc gap-x-4">
								<li>
									<Link
										href={
											isFilled.link(facebook_link)
												? facebook_link.url ?? '#'
												: '#'
										}
										className="group/link block"
									>
										<FacebookIcon className="pointer-events-none stroke-white group-hover/link:stroke-theme-yellow-text" />
									</Link>
								</li>

								{isFilled.link(twitter_link) && (
									<li>
										<Link
											href={
												isFilled.link(twitter_link)
													? twitter_link.url ?? '#'
													: '#'
											}
											className="group/link block"
										>
											<TwitterIcon className="pointer-events-none stroke-white group-hover/link:stroke-theme-yellow-text" />
										</Link>
									</li>
								)}

								<li>
									<Link
										href={
											isFilled.link(instagram_link)
												? instagram_link.url ?? '#'
												: '#'
										}
										className="group/link block"
									>
										<InstagramIcon className="pointer-events-none stroke-white group-hover/link:stroke-theme-yellow-text" />
									</Link>
								</li>
							</ul>
						</section>

						{/* Copyright section */}
						<section>
							<p className="text-sm text-white lg:hidden">{copyright_text}</p>
						</section>
					</div>
				</div>

				{/* Bottom Content */}
				<div className="hidden w-full pb-8 lg:block">
					<p className="text-white">{copyright_text}</p>
				</div>
			</div>
		</footer>
	);
};

const ClientSideFooter = dynamic(() => Promise.resolve(Footer), {
	ssr: false,
});

export default ClientSideFooter;
