import { PrismicRichText, PrismicRichTextProps } from '@prismicio/react';
import React from 'react';

type PrismicUnrichTextProps = {
	className?: string;
} & PrismicRichTextProps;

export const PrismicUnrichText = ({ className, ...props }: PrismicUnrichTextProps) => {
	return (
		<PrismicRichText
			{...props}
			field={props.field}
			components={{
				heading1: ({ children }) => <h1 className={className}>{children}</h1>,
				heading2: ({ children }) => <h2 className={className}>{children}</h2>,
				heading3: ({ children }) => <h3 className={className}>{children}</h3>,
				heading4: ({ children }) => <h4 className={className}>{children}</h4>,
				heading5: ({ children }) => <h5 className={className}>{children}</h5>,
				heading6: ({ children }) => <h6 className={className}>{children}</h6>,
				paragraph: ({ children }) => <p className={className}>{children}</p>,
				preformatted: ({ children }) => <pre className={className}>{children}</pre>,
				strong: ({ children }) => <strong className={className}>{children}</strong>,
				em: ({ children }) => <em className={className}>{children}</em>,
				listItem: ({ children }) => <li className={className}>{children}</li>,
				oListItem: ({ children }) => <ol className={className}>{children}</ol>,
				list: ({ children }) => <li className={className}>{children}</li>,
				oList: ({ children }) => <ol className={className}>{children}</ol>,
				// image: ({ children }) => <img className={className}>{children}</img>,
				// embed: ({ children }) => <embed className={className}>{children}</embed>,
				hyperlink: ({ children }) => <a className={className}>{children}</a>,
				label: ({ children }) => <label className={className}>{children}</label>,
				// span: ({ children }) => <span className={className}>{children}</span>,
			}}
		/>
	);
};
